import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트, 인터파크·트리플과 함께 해외로밍 데이터 상품 무료 이벤트 진행",
  "| eSIM·USIM 모두 가능, 전세계 140개국에서 5일간 총 2.5GB 데이터 제공… 소진 후에도 무료 사용 가능하도록 설계",
  "| 해외 여행지 도착 후 현지 유심 구매해야 했던 불편 해소… 24시간 상담센터 함께 운영",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/31/31_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">△ 핀다이렉트 X 인터파크·트리플 로밍 데이터 무료 이벤트</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 자사 통신플랫폼 핀다이렉트(https://www.pindirectshop.com)와 인터파크·트리플이 손잡고 해외로밍
      상품을 출시하고 무료 이벤트를 진행한다고 22일 밝혔다.
      <br />
      <br />
      무료로 제공받는 데이터양은 매일 500MB이며 최대 5일까지 연달아 제공된다. 1인당 최대 받을 수 있는 데이터양은 총 2.5GB이다.
      이는 타사 로밍 상품 대비 비교 불가한 혜택이다. 기본 데이터 소진 후에도 제한된 속도로 무료 사용 가능하도록 설계되어 있다.
      <br />
      <br />
      사용 가능한 나라는 140개국으로, 인터파크·트리플 내 판매 중인 전체 항공권과 여행상품을 커버하는 수준이다. 즉각 대응이 가능한
      로밍 전문 상담 센터도 24시간 운영한다.
      <br />
      <br />
      이용방법은 간단하다. 여행 전 핀다이렉트 로밍 이벤트 페이지에 접속하여 가입 후 국가 및 기간/데이터를 설정, USIM 혹은 eSIM 중
      선택하면 된다. USIM을 선택할 경우 선택한 배송지로 실물을 받아 이용할 수 있으며, eSIM을 선택할 경우 발급되는 QR코드를 통해
      이용할 수 있다.
      <br />
      <br />
      특히 ‘핀다이렉트 로밍 eSIM’의 경우 평균 1분 이내로 내 폰에서 손쉽게 개통 후 바로 사용 가능할 수 있도록 강화된 UX 편의성이
      돋보인다.
      <br />
      <br />
      이를 통해 스테이지파이브는 무거운 포켓 와이파이 혹은 설정이 복잡하고 분실 우려가 있었던 해외 유심, 그리고 값비싼 로밍 상품을
      사용하던 유저 경험을 완벽히 바꿔 놓을 수 있을 것으로 기대하고 있다. 해당 상품은 바로 출국하지 않아도 수령 후 180일 내에는
      자유롭게 사용이 가능하다.
      <br />
      <br />
      스테이지파이브 관계자는 “해당 이벤트를 통해 핀다이렉트가 제공하는 차별화된 통신 서비스를 체험할 수 있는 계기가 될 수 있기를
      바라며, 향후 자사 요금제를 구매한 고객 대상으로 로밍 무료 혜택을 상시적으로 제공할 계획을 가지고 있다”고 밝혔다. 또한,
      “핀다이렉트에서는 로밍 이벤트 외에도 세컨 넘버 추가 6개월 무료 등 여러 혜택을 준비했으니 많은 관심 가져 달라”라고 말했다.
      <br />
      <br />
      이번 해외로밍 이벤트는 22일부터 23년 1월 31까지 진행되며, 핀다이렉트·인터파크·트리플 각 사 홈페이지 및 앱에서 신청 가능하다.
      <br />
      <br />
      한편, 스테이지파이브는 2017년 카카오 계열사로 편입된 통신사로, 요금제 및 디바이스를 아우르는다양한 통신사업을 영위하고 있다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2022년 12월 21일 17:00 *엠바고 (12월 22일 조간 즉시배포) "
      pageInfo="*첨부사진 1매"
      title="핀다이렉트, 인터파크·트리플과 손잡고 140개국 로밍 데이터 무료 이벤트"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
